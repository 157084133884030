import React from "react";
import "../../stylesheets/loginPage/LoginPage.css";
import "../../stylesheets/DesktopMedia.css";
import "../../stylesheets/Button.css";
import "../../stylesheets/Common.css";
import Button from "../common/Button";
import * as constants from '../../config/Constants';
import { getDataResponse as initUser } from "../../service/serviceCalls.js";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
    const navigate = useNavigate();
    const initApiUser = constants.initUser;
    const onClick = async () => {
    let tokenValidation;
    try{
      tokenValidation = await initUser(initApiUser);
    }
    catch(err){
      if(err.code==="ERR_NETWORK")
      {
          navigate("/NetworkError");
          return;
      }
    }
    if (tokenValidation && tokenValidation.status === 200) {
        navigate("/home");
    }
    else {
        navigate("/");
    }
    };
    return (
        <div className="completeBody">
            <div className="mainDiv">
                <div className="bodyContent">
                    <div className="error_container">
                        <div className="error_block">
                            <h1>404</h1>
                            <h3>Oops... Page Not Found!</h3>
                            <p>Try using the button below to go to main page of the site</p>
                            <Button name="Click here" action={onClick}></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PageNotFound;
