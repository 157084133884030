import React from "react";
import "../../stylesheets/loginPage/LoginPage.css";
import "../../stylesheets/DesktopMedia.css";
import "../../stylesheets/Button.css";
import "../../stylesheets/Common.css";

function NetworkError() {
    return (
        <div className="completeBody">
            <div className="mainDiv">
                <div className="bodyContent">
                    <div className="error_container">
                        <div className="error_block">
                            <h1>Network Error</h1>
                            <h3>Oops... Page Not Found!</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default NetworkError;
