import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../stylesheets/Common.css";
import "../../stylesheets/search/SearchBox.css";
import Button from "../common/Button";
import scanLogo from "../../assets/images/scan_logo.png";
import closeIcon from "../../assets/images/close-icon.png";
import Scanner from "./Scanner";
import { SessionContext } from "../../config/SessionContext.js";

function Search() {
  const { updateErrorMessage } = useContext(SessionContext);
  const { updateImei } = useContext(SessionContext);
  window.history.replaceState({}, document.title);
  const navigate = useNavigate();
  const [orderNoSearch, setOrderNoSearch] = useState("");
  const [errorMessages, setErrorMessages] = useState(false);
  const { state } = useLocation();
  const [visible, setVisible] = useState(!!state);
  /* Bar Code Scan */
  const [camera, setCamera] = useState(false);
  const onDetected = (result, callback) => {
    setCamera(false);
    if (document.getElementById("searchBox")) {
      document.getElementById("searchBox").value = result;
      setOrderNoSearch(result);
      document.getElementById("searchBox").focus();
      callback.stop();
    }
  };
  const setVal = () => {
    setCamera(!camera);
    setOrderNoSearch("");
  };

  const onClick = () => {
    window.history.replaceState({}, document.title);
    navigate("/detail", { state: { orderNoSearch } });
  };
  useEffect(() => {
    updateErrorMessage(null);
    updateImei("");
    if (orderNoSearch.length === 0) {
      setErrorMessages(false);
    }
    if (orderNoSearch && orderNoSearch !== "") {
      setVisible(false);
    }
    localStorage.removeItem("dataKey");
    localStorage.removeItem("imei status");
    localStorage.removeItem("imeiCode");
    localStorage.removeItem("imeiNo");
    localStorage.removeItem("flag");
    localStorage.removeItem("backFlag");
    localStorage.setItem("transactionId", null);
    localStorage.setItem("imageCount", 0);
  }, [orderNoSearch, updateImei, updateErrorMessage]);
  return (
    <div className="completeBody">
      <div className="mainDiv">
        <div className="bodyContent">
          {camera ? (
            <div className="container">
              <div className="searchScanner">
                {camera && <Scanner onDetected={onDetected} />}
              </div>
            </div>
          ) : (
            <button
              onClick={setVal}
              className="scanSearchButton"
              data-testid="scanButton"
            >
              <div className="scanBox">
                <div className="centerAlignPadding">
                  <img src={scanLogo} alt="croma" className="imageScan" />
                  <span className="scanOrderNo">
                    SCAN AWB OR <br></br>ORDER NUMBER
                  </span>
                </div>
              </div>
            </button>
          )}
          <div className="centerAlignForOr">OR</div>
          <h4 className="orderNoText">Enter AWB or Order Number</h4>
          <input
            className={
              errorMessages || (visible && state)
                ? "searchBoxColor"
                : "searchBox"
            }
            id="searchBox"
            type="search"
            data-testid="orderno-input"
            autoComplete="off"
            value={orderNoSearch}
            placeholder={orderNoSearch || ""}
            onChange={(event) => setOrderNoSearch(event.target.value)}
          />
          {visible && state && (
            <span className="errorMessage" data-testid="error-msg">
              <img src={closeIcon} alt="close" className="imageScan" />
              <span className="errorMsgText">{state}</span>
            </span>
          )}
          <div className="orderSubmitButton">
            <Button
              name="SUBMIT"
              disable={!orderNoSearch}
              action={onClick}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Search;
