import React from "react";
import "../../stylesheets/Spinner.css";

export default function LoadingSpinner() {
  return (
    <div
      className={
        localStorage.getItem("isFlag") === "true"
          ? "spinner-container-camera"
          : "spinner-container"
      }
    >
      <div className="loading-spinner" data-testid="loading-spinner"></div>
    </div>
  );
}
