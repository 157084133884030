import LoginPage from "./components/loginPage/LoginPage.jsx";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Header from "./components/common/Header.jsx";
import Search from "./components/searchPage/Search.jsx";
import DetailPage from "./components/detailPage/DetailPage.jsx";
import Checklist from "./components/checklistPage/ChecklistPage.jsx";
import LoadingSpinner from "./components/detailPage/LoadingSpinner.jsx";
import PageNotFound from "./components/common/PageNotFound.jsx";
import NetworkError from "./components/common/NetworkError.jsx";
import * as constants from "./config/Constants.js";
import { getDataResponse as initUser } from "./service/serviceCalls.js";
import { useEffect, useState, useCallback, useMemo } from "react";
import global from "globals";
import Cookies from "universal-cookie";

const HeaderLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

function App() {
  const FrameBuster = () => {
    useEffect(() => {
      const preventFrameBusting = () => {
        if (window.top !== window.self) {
          window.top.location.href = window.self.location.href;
        }
      };
      preventFrameBusting();
    }, []);
  };
  FrameBuster();
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const initApiUser = constants.initUser;
  const cookies = useMemo(() => new Cookies(), []);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  if (params.get("token") !== null && params.get("token") !== undefined) {
    global.accessToken = params.get("token");
    cookies.set("logintoken", params.get("token"));
  }

  let isTokenError = params.get("tokenError") === "null";

  const validateToken = useCallback(async () => {
    let tokenValidation;
    try {
      tokenValidation = await initUser(initApiUser);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 500) {
          setTokenIsValid(false);
          setIsLoading(false);
          cookies.remove("logintoken");
          cookies.remove("token");
        }
      }
    }
    if (tokenValidation && tokenValidation.status === 200) {
      setTokenIsValid(true);
      setIsLoading(false);
      let firstNameChar = tokenValidation.data.userDetails.fullName.charAt(0);
      let fullName = tokenValidation.data.userDetails.fullName;
      localStorage.setItem("Name", firstNameChar);
      localStorage.setItem("FullName", fullName);
    } else {
      setTokenIsValid(false);
      setIsLoading(false);
    }
  }, [cookies, initApiUser]);

  useEffect(() => {
    validateToken();
  }, [validateToken]);

  return (
    <Router>
      <div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Routes>
            {tokenIsValid ? (
              <>
                <Route
                  path="/"
                  element={<LoginPage isLoggedIn={isTokenError} />}
                />
                <Route path="/home" element={<HeaderLayout />}>
                  <Route index element={<Search />} />
                </Route>
                <Route path="/detail" element={<HeaderLayout />}>
                  <Route index element={<DetailPage />} />
                </Route>
                <Route path="/checklist" element={<HeaderLayout />}>
                  <Route index element={<Checklist />} />
                </Route>
                <Route path="*" element={<PageNotFound />} />
                <Route path="/NetworkError" element={<NetworkError />} />
              </>
            ) : (
              <>
                <Route path="/" element={<LoginPage isLoggedIn={false} />} />
                <Route
                  path="/home"
                  element={<LoginPage isLoggedIn={false} />}
                />
                <Route
                  path="/detail"
                  element={<LoginPage isLoggedIn={false} />}
                />
                <Route
                  path="/checklist"
                  element={<LoginPage isLoggedIn={false} />}
                />
                <Route path="*" element={<PageNotFound />} />
                <Route path="/NetworkError" element={<NetworkError />} />
              </>
            )}
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
