import callIcon from "../../assets/images/callIcon.png";
function CustomerDetail({ customerData }) {
  let customerState = customerData
    ? customerData.customerInfo.personalInfo._State.split("-")
    : "";
  let customerCity = customerData
    ? customerData.customerInfo.personalInfo._City
    : "";
  let customerAddress1 = customerData
    ? customerData.customerInfo.personalInfo._IRLAddressLine1.lastIndexOf(",")
    : "";
  let customerAddress2 = customerData
    ? customerData.customerInfo.personalInfo._IRLAddressLine2.lastIndexOf(",")
    : "";
  let customerAddress_1 = customerData
    ? customerData.customerInfo.personalInfo._IRLAddressLine1
    : "";
  let customerAddress_2 = customerData
    ? customerData.customerInfo.personalInfo._IRLAddressLine2
    : "";
  if (customerAddress1 < 0)
    customerAddress_1 =
      customerData.customerInfo.personalInfo._IRLAddressLine1 + ",";
  if (customerAddress2 < 0)
    customerAddress_2 =
      customerData.customerInfo.personalInfo._IRLAddressLine2 + ",";
  return (
    <div className="customerData">
      <h4 className="headerDetails">Customer Details</h4>
      <div className="cardAuto">
        <div className="customerName" data-testid="CustomerNames">
          {customerData
            ? customerData.customerInfo._FirstName +
              " " +
              customerData.customerInfo._LastName
            : ""}
        </div>
        <div data-testid="CustomersContactNumber">
          {customerData?.customerInfo._MobilePhone && (
            <img src={callIcon} alt="CallIcon" className=""></img>
          )}{" "}
          <a
            className="customerMobile"
            href={`tel:${
              customerData ? customerData.customerInfo._MobilePhone : ""
            }`}
          >
            {customerData ? customerData.customerInfo._MobilePhone : ""}
          </a>
        </div>
      </div>
      <div className="cusAddress col-9" data-testid="CustomersAddresses">
        {customerAddress_1}
        <div>{customerAddress_2}</div>
        <div>
          {customerCity.charAt(0).toUpperCase() +
            customerCity.slice(1).toLowerCase()}
          ,{" "}
          {customerData
            ? customerState[0].charAt(0).toUpperCase() +
              customerState[0].slice(1).toLowerCase() +
              " " +
              customerData.customerInfo.personalInfo._ZipCode +
              "."
            : ""}
        </div>
      </div>
    </div>
  );
}

export default CustomerDetail;
