import React, { useEffect } from "react";
import "../../stylesheets/loginPage/LoginPage.css";
import "../../stylesheets/Common.css";
import Button from "../common/Button";
import imageCroma from "../../assets/images/logo.png";
import doorstepQualityCheck from "../../assets/images/Doorstep_Quality_Check.png";
import "../../stylesheets/Landscape.css";
import * as constants from "../../config/Constants";
import { useNavigate, useLocation } from "react-router-dom";

function LoginPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenError = queryParams.get("tokenError");
  const onClick = async () => {
    window.location.assign(process.env.REACT_APP_MICROSOFT_LOGIN);
  };
  useEffect(() => {
    localStorage.removeItem("flag");
    localStorage.removeItem("dataKey");
    localStorage.removeItem("imei status");
    localStorage.removeItem("imeiCode");
    localStorage.removeItem("imeiNo");
    if (props.isLoggedIn === true) {
      navigate("/home");
    } else {
      navigate("/");
    }
  }, [navigate, props.isLoggedIn]);
  return (
    <div className="completeBody">
      <div className="mainDiv">
        <div className="loginDiv">
          {props.isLoggedIn && (
            <p className="notLoggedIn">
              Your are not Logged In. Click below link to login.
            </p>
          )}
          <div className="imageText">
            <img src={imageCroma} alt="croma" className="imageAlphabet"></img>
          </div>
          <div className="headlineDiv">
            <img
              src={doorstepQualityCheck}
              alt="Doorstep Quality Check"
              className="imageAlphabet"
            ></img>
          </div>
          {tokenError === "true" && (
            <p className="tokenError">{constants.tokenExpiryChecklistPage}</p>
          )}
          {tokenError === "yes" && (
            <p className="tokenError">{constants.tokenExpiryForOtherPage}</p>
          )}
          <div className="buttonDiv">
            <Button id="signinButton" name="SIGN IN" action={onClick}></Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
