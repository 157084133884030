import React from "react";
function StepperBar({ stepCount, onData }) {
  const stepData = [
    { id: "first", name: "QC Checklist" },
    { id: "second", name: "Upload Image" },
  ];
  const passIndex = (i) => {
    onData(i);
  };
  return (
    <div className="styledStepperContainer">
      <div className="styledStepperHeader">
        {stepData.length
          ? stepData.map((step, index) => {
              let classNameValue;
              if (index === 1) {
                classNameValue =
                  stepCount >= index && stepCount === 1 ? "completed" : "";
              } else {
                classNameValue =
                  stepCount >= index && stepCount === 0
                    ? "completed"
                    : "completedStepOne";
              }
              return (
                <div
                  id="styledStepperHeaderItem"
                  data-testid="styledStepperHeaderItem"
                  key={step.id}
                  className={classNameValue}
                >
                  {index === 0 ? (
                    <div
                      className={
                        stepCount === 1 ? "stepperCompleted" : "stepperLine"
                      }
                    ></div>
                  ) : (
                    ""
                  )}
                  <button
                    onClick={() => passIndex(index)}
                    className="stepperButton"
                  >
                    <div className="step-counter">
                      <div className="step-in"></div>
                    </div>
                  </button>
                  <button
                    onClick={() => passIndex(index)}
                    className={
                      stepCount >= index
                        ? "step-name stepperBold stepperButton"
                        : "step-name stepperButton"
                    }
                  >
                    <div
                      className={
                        stepCount >= index
                          ? "step-name stepperBold"
                          : "step-name"
                      }
                    >
                      {step.name}
                    </div>
                  </button>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}

export default StepperBar;
