import React, { createContext, useState, useMemo, useCallback } from "react";

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [imeiErrorMessage, setImeiErrorMessage] = useState(null);
  const [imeiFetch, setImeiFetch] = useState("");
  const [pageReload, setPageReload] = useState(null);

  const updateImei = useCallback((value) => {
    setImeiFetch(value);
  }, []);

  const updatePageReload = useCallback((value) => {
    setPageReload(value);
  }, []);

  const updateErrorMessage = useCallback((message) => {
    setImeiErrorMessage(message);
  }, []);

  return (
    <SessionContext.Provider
      value={useMemo(
        () => ({
          imeiErrorMessage,
          updateErrorMessage,
          imeiFetch,
          updateImei,
          pageReload,
          updatePageReload,
        }),
        [
          imeiErrorMessage,
          imeiFetch,
          updateImei,
          updateErrorMessage,
          pageReload,
          updatePageReload,
        ]
      )}
    >
      {children}
    </SessionContext.Provider>
  );
};
