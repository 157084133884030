import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import "../../stylesheets/checklistPage/QuestioningPage.css";

function MyVerticallyCenteredModal(props) {
  const [disable, setDisable] = useState(true);
  useEffect(() => {
    if (props.imeinumber) setDisable(false);
    else setDisable(true);
  }, [props.imeinumber]);

  return (
    <Modal
      {...props}
      id="livestream_scanner"
      size="lg"
      className="scannerModalBody"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="scannerModalHeader">
        <Modal.Title id="contained-modal-title-vcenter">
          Barcode Scanner
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="scannerModalBodyDiv">
        <div className="container">{props.children}</div>
      </Modal.Body>
      <Modal.Footer className="scannerModalFooterDiv">
        <label className="imeiCapture">{props.imeinumber}</label>
        <button
          onClick={props.onHide}
          disabled={props.imeinumber === "" ? disable : false}
          className="scannerSubmit"
        >
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default MyVerticallyCenteredModal;
