import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import noImage from "../../assets/images/no_image_available.jpg";
import deleteIcon from "../../assets/images/deleteIcon.png";
import { deleteDataResponse } from "../../service/serviceCalls";
import { useNavigate } from "react-router";
import * as constants from "../../config/Constants";
import leftArrow from "../../assets/images/leftArrow.png";
import rightArrow from "../../assets/images/rightArrow.png";
import LoadingSpinner from "../detailPage/LoadingSpinner";
import Image from "../common/Image.jsx";

function ImageGalleryView({
  imageData,
  imagePathId,
  showModal,
  setShowModal,
  imageGallerySource,
  imageGallerySourceIndex,
  setImageGallerySourceIndex,
  fetchImages,
  isLoading,
  setIsLoading,
}) {
  const navigate = useNavigate();
  const [currentImageIndices, setCurrentImageIndices] = useState(
    imageGallerySourceIndex + 1
  );
  let currentImage;
  const [imageList, setImageList] = useState([]);
  useEffect(() => {
    setImageList(
      imageData.map((image) => ({
        original: image,
      }))
    );
  }, [imageData]);

  useEffect(() => {
    setCurrentImageIndices(imageGallerySourceIndex + 1);
  }, [showModal, imageGallerySourceIndex]);

  const deleteIconClick = () => {
    removeImage(currentImageIndices);
  };

  const handleSlide = (index) => {
    currentImage = index + 1;
    setCurrentImageIndices(currentImage);
  };

  const handleImageGalleryClose = () => {
    setShowModal(false);
  };

  const removeImage = (currentImageIndex) => {
    imageData.map((data, indexs) => {
      const indexIncrement = indexs + 1;

      if (indexIncrement === currentImageIndex) {
        setImageList(
          imageList.filter((_, index) => {
            return currentImage !== currentImageIndex;
          })
        );
        imagePathId.filter((_, index) => currentImage !== indexs);
        const imagePathIdToDelete = imagePathId[currentImageIndex - 1];
        const deleteImageApi = constants.imageDeleteApi + imagePathIdToDelete;

        setIsLoading(true);
        deleteDataResponse(deleteImageApi)
          .then((response) => {
            if (
              response.status === 200 &&
              imageData.length - 1 === currentImageIndices - 1
            ) {
              setImageGallerySourceIndex(currentImageIndices - 2);
            } else {
              setImageGallerySourceIndex(currentImageIndex - 1);
            }

            fetchImages();
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.code) {
              if (
                error.code === "ERR_NETWORK" ||
                error.code === "ERR_BAD_RESPONSE"
              ) {
                navigate("/NetworkError");
              }
              if (
                error.response &&
                error.response.data.message === "Invalid token"
              ) {
                navigate(`/?tokenError=${true}`);
              }
            }
          });
      }
      return data;
    });
  };

  const renderLeftNav = (onClick, disabled) => {
    return (
      <div
        className={`${
          currentImageIndices === 1 ? "image-left-arrow-disabled" : ""
        }`}
      >
        <Image
          classNameAttribute="infoIconModelButton"
          action={onClick}
          imageSource={leftArrow}
          altText="leftArrow"
          imageClass="image-gallery-custom-left-nav"
          imageTestId="leftArrow"
        />
      </div>
    );
  };
  const renderRightNav = (onClick, disabled) => {
    return (
      <div
        className={`${
          currentImageIndices === imageList.length
            ? "image-right-arrow-disabled"
            : ""
        }`}
      >
        <Image
          classNameAttribute="infoIconModelButton"
          action={onClick}
          imageSource={rightArrow}
          altText="rightArrow"
          imageClass="image-gallery-custom-right-nav"
          imageTestId="rightArrow"
          disabled={disabled}
        />
      </div>
    );
  };
  return (
    <div>
      <Modal show={showModal} className="imageGalleryModalPreview" centered>
        <>
          <Modal.Header pt-reduced="true" pb-reduced="true">
            <div className="allImagesPopup">{imageGallerySource}</div>
            {imageList.length > 0 && (
              <div className="imageIndexPopup">
                {currentImageIndices}/{imageList.length}
              </div>
            )}
          </Modal.Header>
          {!isLoading ? (
            <Modal.Body
              className="imageGalleryModalBody"
              data-testid="imageGallery"
            >
              {imageList.length > 0 && (
                <div className="deleteIcon">
                  <Image
                    classNameAttribute="infoIconModelButton"
                    action={deleteIconClick}
                    testId="deleteIcon"
                    imageSource={deleteIcon}
                    altText="deleteIcon"
                    imageClass="deleteImageIcon"
                  />
                </div>
              )}
              <div>
                {imageList.length > 0 ? (
                  <ImageGallery
                    data-testid="imageGallery"
                    items={imageList}
                    showThumbnails={false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    startIndex={imageGallerySourceIndex}
                    onSlide={handleSlide}
                    fullscreen={true}
                    renderLeftNav={renderLeftNav}
                    renderRightNav={renderRightNav}
                  />
                ) : (
                  <img alt="" src={noImage} height="425em" width="100%" />
                )}
              </div>
            </Modal.Body>
          ) : (
            <LoadingSpinner />
          )}
          <Modal.Footer className="imageGalleryModalFooter">
            <button
              onClick={() => {
                handleImageGalleryClose();
              }}
              className="imageClosePopup"
              data-testid="closeButton"
            >
              CLOSE
            </button>
          </Modal.Footer>
        </>
      </Modal>
    </div>
  );
}

export default ImageGalleryView;
