import axios from "axios";
import global from "globals";
import Cookies from "universal-cookie";

export const postData = (endpoint, data) => {
  const cookies = new Cookies();
  const cookie = cookies.get("logintoken");
  let token =
    global.accessToken !== null && global.accessToken !== undefined
      ? global.accessToken
      : cookie;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const res = axios
    .post(process.env.REACT_APP_BASE_URL + endpoint, data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (!(err instanceof Error)) {
        return Promise.reject(new Error(err));
      }
      return Promise.reject(err);
    });
  return res;
};

export const getData = async (endpoint) => {
  const cookies = new Cookies();
  const cookie = cookies.get("logintoken");
  let token =
    global.accessToken !== null && global.accessToken !== undefined
      ? global.accessToken
      : cookie;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios
    .get(process.env.REACT_APP_BASE_URL + endpoint, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (!(error instanceof Error)) {
        return Promise.reject(new Error(error));
      }
      return Promise.reject(error);
    });
  return res;
};
