import React, { useState, useRef } from "react";
import "../../stylesheets/MobileMedia.css";
import "../../stylesheets/TabletMedia.css";
import "../../stylesheets/Landscape.css";
import "../../stylesheets/SmallScreenMedia.css";
import "../../stylesheets/Common.css";
import "../../stylesheets/DesktopMedia.css";
import "../../stylesheets/Switzer.css";
import cromaLogo from "../../assets/images/Croma_Logo.svg";
import logoutIcon from "../../assets/images/logoutIcon.png";
import { useNavigate } from "react-router-dom";
import { getDataResponse } from "../../service/serviceCalls.js";
import * as constants from "../../config/Constants";
import global from "globals";
import Cookies from "universal-cookie";
import Image from "../common/Image.jsx";

function Header() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  let firstNameChar = localStorage.getItem("Name");
  let userFullName = localStorage.getItem("FullName");
  const [view, setView] = useState(false);
  const ref = useRef();
  const pageRedirect = async () => {
    navigate("/home");
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setView(false);
    }
  };

  const bodyTag = document.getElementsByClassName("completeBody")[1];
  if (bodyTag !== undefined) {
    bodyTag.addEventListener("mousedown", handleClickOutside);
  }

  function menuToggle() {
    setView((state) => !state);
  }
  const logOutClick = async () => {
    global.accessToken = null;
    cookies.remove("logintoken");
    const logoutApi = constants.logOutApi;
    let logoutData;
    try {
      logoutData = await getDataResponse(logoutApi);
    } catch (err) {
      if (err.code) {
        if (err.code === "ERR_NETWORK" || err.code === "ERR_BAD_RESPONSE") {
          navigate("/NetworkError");
          return;
        }
      }
    }
    if (logoutData?.hasOwnProperty("data"))
      window.location.assign(logoutData.data.url);
  };
  return (
    <header className="completeBody header position-relative">
      <div className="headerSpan">
        <div>
          <Image
            classNameAttribute="infoIconModelButton"
            action={pageRedirect}
            testId="scanIcon"
            imageSource={cromaLogo}
            altText="Logo"
            imageClass="heading"
          />
        </div>
        <div className="action">
          <button
            className="profile"
            data-testid="profile"
            onClick={menuToggle}
            ref={ref}
          >
            <div className="userFirstLetter">
              <div className="nameCircle">{firstNameChar}</div>
            </div>
          </button>
          {view && (
            <div className="menu active">
              <div className="p-1 menuHeader rounded-top">
                <div className="p-2 d-flex align-items-center justify-content-center">
                  <div className="nameCircle2 mx-2 px-1">{firstNameChar}</div>
                  <div className="userFullName px-1">{userFullName}</div>
                </div>
              </div>
              <div className="p-2 d-flex logOutMenu rounded-top">
                <button
                  className="d-flex logOutClass"
                  data-testid="logout"
                  onClick={logOutClick}
                >
                  <div className="">
                    <img
                      src={logoutIcon}
                      alt="Logout"
                      className="logoutImage"
                    ></img>
                  </div>
                  <div className="px-1 logOutLabel">Log Out</div>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
