import React from "react";
import "../../stylesheets/Button.css";
import "../../stylesheets/MobileMedia.css";
import "../../stylesheets/DesktopMedia.css";
import "../../stylesheets/TabletMedia.css";
import "../../stylesheets/Landscape.css";
import "../../stylesheets/SmallScreenMedia.css";

function Button({ name, action, disable, dataTestId }) {
  let class_name = "";
  if (name === "SIGN IN") {
    class_name = "commonSiginButton proceedPadding";
  } else if (name === "SUBMIT") {
    class_name = "commonSearchButton proceedPadding";
  } else {
    class_name = "commonSearchButton proceedPadding";
  }

  return (
    <button
      type="button"
      disabled={disable}
      className={class_name}
      onClick={action}
      data-testid={dataTestId}
    >
      {name}
    </button>
  );
}

export default Button;
