import { useState, useEffect } from "react";
import image from "../../assets/images/success.png";
import failureImage from "../../assets/images/failure.png";
import * as constants from "../../config/Constants";

function Card({ cardData, verifyStatus, orderNoSearch, pickUpStatus }) {
  let class_name = "";
  const [pickUp, setPickUp] = useState(false);
  useEffect(() => {
    if (pickUpStatus === "PICK_UP") {
      setPickUp(true);
    } else if (pickUpStatus === "DO_NOT_PICK_UP") {
      setPickUp(false);
    }
  }, [pickUpStatus]);
  if (verifyStatus && verifyStatus === 201 && pickUp) {
    class_name = "card maincardSuccess";
  } else if (verifyStatus && verifyStatus === 201 && !pickUp) {
    class_name = "card maincardFailure";
  } else class_name = " card maincardDiv";

  let filteredValue = [];
  if (cardData) {
    const hasValue = cardData.item.filter((el) => el._ItemType === "ZPAK");
    filteredValue = hasValue.length > 0 ? hasValue : cardData.item;
  }
  return (
    <>
      {cardData &&
        filteredValue.map((data) => {
          return (
            <div key={data._id} className={class_name}>
              <div className="card-body cardbackgroundColor">
                <div className="cardPadding">
                  <div className="cardAuto">
                    <div className="returnPackageOrderno">
                      {cardData.shipmentNo}
                    </div>
                    <div className="categoryName">
                      {cardData ? cardData.category[0].category : ""}
                    </div>
                  </div>
                  <div>
                    <h5 className="card-title">{data._ItemDesc}</h5>
                    {verifyStatus && verifyStatus === 201 ? (
                      <div className="verification">
                        <img
                          src={pickUp ? image : failureImage}
                          alt={pickUp ? "successful" : "Failed"}
                          className="verifiedImage"
                        />
                        <p
                          className={
                            pickUp ? "verificationText" : "failureText"
                          }
                        >
                          {pickUp
                            ? constants.verifiedText
                            : constants.failedText}
                        </p>
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}

export default Card;
