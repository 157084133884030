import React from "react-dom";
import "../../stylesheets/checklistPage/ChecklistPage.css";
import QuestioningPage from "./QuestioningPage";
import pic from "../../assets/images/arrow-left.png";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import LoadingSpinner from "../detailPage/LoadingSpinner";
import StepperBar from "../common/StepperBar";
import UploadImagesPage from "./UploadImagesPage";
import Image from "../common/Image";

function Checklist() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [updateStep, setUpdateStep] = useState(0);
  const incrementStep = () => {
    setUpdateStep(updateStep + 1);
  };

  const decrementStep = () => {
    setUpdateStep(updateStep - 1);
  };

  const backArrowClick = () => {
    if (updateStep === 0) {
      navigate("/detail", {
        replace: true,
        state: {
          orderNoSearch,
        },
      });
    } else {
      decrementStep();
    }
  };

  const orderNoSearch = state?.orderNoSearch;
  useEffect(() => {
    if (state === null) {
      navigate("/home");
    } else {
      setIsLoading(false);
    }
  }, [navigate, state]);

  const handleDataFromChild = (data) => {
    if (updateStep >= data) {
      setUpdateStep(data);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="completeBody">
          <div className="checklistParentDiv">
            <div className="checkListContent" data-testid="checkListTest">
              <div className="productHeader">
                <Image
                  classNameAttribute="infoIconModelButton"
                  action={backArrowClick}
                  imageSource={pic}
                  altText="arrowPic"
                  imageClass="arrowPic"
                />

                <h3 className="headerText">{state.productName}</h3>
              </div>
              <StepperBar
                onData={handleDataFromChild}
                stepCount={updateStep}
              ></StepperBar>
            </div>
            <div>
              {updateStep === 0 ? (
                <QuestioningPage
                  categoryId={state.categoryId}
                  increment={incrementStep}
                  decrement={decrementStep}
                />
              ) : (
                false
              )}
            </div>
            <div>
              {updateStep === 1 ? (
                <UploadImagesPage
                  increment={incrementStep}
                  decrement={decrementStep}
                />
              ) : (
                false
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Checklist;
