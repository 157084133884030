import { useState, useEffect, useCallback, useRef, useContext } from "react";
import { Grid } from "@mui/material";
import Card from "./Card";
import Button from "../common/Button";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import { SessionContext } from "../../config/SessionContext.js";

function OrderDetail({
  orderDetailData,
  verifyStatus,
  orderNoSearch,
  finalDecision,
}) {
  const navigate = useNavigate();
  const { pageReload } = useContext(SessionContext);
  const returnInitatedDate = new Date(orderDetailData.orderDate);
  const returnPickUpDate = new Date(orderDetailData.pickupDate);
  const returnInitatedFormatDate = `${returnInitatedDate.toLocaleString(
    "default",
    { timeZone: "Asia/Kolkata", day: "2-digit", month: "short" }
  )}, ${returnInitatedDate.toLocaleString("default", {
    timeZone: "Asia/Kolkata",
    year: "numeric",
  })}`;
  const returnInitatedFormatTime = returnInitatedDate.toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
    hour12: true,
    hour: "2-digit",
    minute: "numeric",
  });
  const returnPickupFormatDate = `${returnPickUpDate.toLocaleString("default", {
    timeZone: "Asia/Kolkata",
    day: "2-digit",
    month: "short",
  })}, ${returnPickUpDate.toLocaleString("default", {
    timeZone: "Asia/Kolkata",
    year: "numeric",
  })}`;
  const categoryNameRef = useRef(null);
  let productId = 0;
  let returnPackagesId = 0;
  let skuCode = 0;
  let validationField = "";
  let transactionId = 0;
  let returnId = 0;
  if (orderDetailData !== undefined) {
    productId = orderDetailData.productCategoryId;
    validationField = orderDetailData.validationField;
    returnPackagesId = orderDetailData.returnReasonId;
    skuCode = orderDetailData.skuCode;
    orderNoSearch = orderDetailData.returnOrderNo;
    transactionId = orderDetailData.transactionId;
    returnId = orderDetailData._id;
  }
  const CategoryIdFetch = (productName) => {
    navigate("/checklist", {
      state: {
        productName,
        productId,
        orderNoSearch,
        returnPackagesId,
        skuCode,
        transactionId,
        returnId,
        validationField,
      },
    });
  };
  const [show, setShow] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  const gotoHome = () => {
    navigate("/home");
  };

  const showPopup = useCallback(() => {
    if (finalDecision && pageReload === false) setShow(true);
  }, [finalDecision, pageReload]);
  const getCategoryName = useCallback(() => {
    const productCard = orderDetailData?.item.filter(
      (el) => el._ItemType === "ZPAK"
    );
    const newCategoryName =
      productCard?.length > 0
        ? productCard[0]._ItemDesc
        : orderDetailData?.item[0]._ItemDesc;
    categoryNameRef.current = newCategoryName;
    setCategoryTitle(newCategoryName);
  }, [orderDetailData, setCategoryTitle]);
  useEffect(() => {
    showPopup();
    getCategoryName();
  }, [getCategoryName, showPopup]);

  const handleClose = () => {
    setShow(false);
  };

  let productQuantities = orderDetailData?.item.filter(
    (el) => el._ItemType === "ZPAK"
  );
  let productQuantity =
    productQuantities && productQuantities.length > 0
      ? orderDetailData.item.filter((el) => el._ItemType === "ZCOM").length
      : orderDetailData && Object.keys(orderDetailData.item).length;

  return (
    <>
      {show && (
        <div>
          <Modal
            size="lg"
            className="verificationModal"
            show={true}
            centered
            onHide={handleClose}
          >
            <Modal.Header
              closeButton
              className="border-0 small pb-0"
            ></Modal.Header>
            <Modal.Body className="pt-0">
              <div>
                <div
                  className={
                    finalDecision && finalDecision === "PICK_UP"
                      ? "verifiedProductSuccess"
                      : "verifiedProductFailure"
                  }
                >
                  {finalDecision && finalDecision === "PICK_UP"
                    ? "Verified Successfully"
                    : "Verification Failed"}
                </div>
                <div className="verifiedSuccessProduct">
                  {categoryTitle}{" "}
                  {finalDecision && finalDecision === "PICK_UP"
                    ? "product QC has been completed successfully"
                    : "product QC has been failed"}
                </div>
                <Button name="Done" action={handleClose}></Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
      <div className="orderData">
        <div className="customerOrderDetail">
          <div className="headerDetails">Order Details</div>
          <div className="customerPackagepickup">PICKUP</div>
        </div>
        <Grid container spacing={2} className="gridSpacing">
          <Grid item xs={7} lg={7}>
            <div className="orderInfo">Order number</div>
            <div className="orderInfoData" data-testid="OrderID">
              {orderDetailData ? orderDetailData.returnOrderNo : ""}
            </div>
          </Grid>
          <Grid item xs={5} lg={5}>
            <div className="orderInfo">Packages</div>
            <div className="orderInfoData" data-testid="ProductsQuantity">
              {productQuantity}
            </div>
          </Grid>
        </Grid>
        <div className="spacePadding">
          <div className="orderInfo">Return Initiated</div>
          <div className="orderInfoData" data-testid="ProductReturnDate">
            {returnInitatedFormatDate} <span className="pipeColor">|</span>{" "}
            {returnInitatedFormatTime}
          </div>
        </div>
        <div className="spacePadding">
          <div className="orderInfo">Return Pickup date</div>
          <div className="orderInfoData" data-testid="ProductPickupDate">
            {returnPickupFormatDate}
          </div>
        </div>
        <div className="spacePadding">
          <div className="orderInfo">Return Reason</div>
          <div className="orderInfoData" data-testid="ProductReturnReason">
            {orderDetailData ? orderDetailData.reason[0].reason : ""}
          </div>
        </div>
        <Card
          cardData={orderDetailData}
          verifyStatus={verifyStatus}
          orderNoSearch={orderNoSearch}
          pickUpStatus={finalDecision}
        />
        {finalDecision && finalDecision === "PICK_UP" && showPopup && (
          <div className="verifiedSuccess">PROCEED TO PICKUP</div>
        )}
        {finalDecision && localStorage.setItem("backFlag", true)}
        {finalDecision && finalDecision !== "PICK_UP" && showPopup && (
          <div className="verifiedSuccess">DO NOT PICKUP</div>
        )}
      </div>
      <div className="proceedPickup">
        <Button
          action={() =>
            verifyStatus !== 201 ? CategoryIdFetch(categoryTitle) : gotoHome()
          }
          name={
            verifyStatus && verifyStatus.verifyStatus !== 200
              ? "Pick up a new product"
              : "Check Product"
          }
        ></Button>
      </div>
    </>
  );
}

export default OrderDetail;
