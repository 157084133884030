import React, { useEffect, useState, useRef, useCallback } from "react";
import Customerdetail from "./CustomerDetail";
import OrderDetail from "./OrderDetail";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import { getDataResponse as orderDetailService } from "../../service/serviceCalls.js";
import * as constants from "../../config/Constants";

function DetailPage() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();
  const dataFetchedRef = useRef(false);
  const verifyStatus = state?.returnStatus;
  const pickUpStatus = state?.finalDecision;
  const tokenExpiryValue = "yes";

  const tokenErrorMesage = useCallback(() => {
    if (localStorage.getItem("flag") === "true") {
      navigate(`/?tokenError=${true}`);
    } else {
      navigate(`/?tokenError=${tokenExpiryValue}`);
    }
  }, [navigate]);

  const apiErrorCalls = useCallback(
    (errorCode, errorMessage) => {
      if (errorCode === "ERR_NETWORK" || errorCode === "ERR_BAD_RESPONSE") {
        navigate("/NetworkError");
        return;
      }
      if (errorMessage === "Invalid token") {
        tokenErrorMesage();
      }
    },
    [navigate, tokenErrorMesage]
  );

  const orderDetailCall = useCallback(async () => {
    if (state && state.orderNoSearch !== "") {
      setIsLoading(true);
      let params = localStorage.getItem("transactionId");
      let detailData;
      const orderApi =
        constants.orderDetailApi +
        state.orderNoSearch +
        `?transactionId=${params}`;
      try {
        detailData = await orderDetailService(orderApi);
        localStorage.setItem(
          "transactionId",
          detailData.data.data[0].transactionId
        );
        setResponse(detailData.data.data[0]);
      } catch (err) {
        if (err.response.status === 404 || err.response.status === 403) {
          window.history.replaceState({}, document.title);
          navigate("/home", {
            state: err?.response.data.message,
          });
        }
        if (err.code) {
          apiErrorCalls(err.code, err.response.data.message);
        }
      }
      setIsLoading(false);
    } else {
      navigate("/home");
    }
  }, [state, setIsLoading, setResponse, navigate, apiErrorCalls]);

  useEffect(() => {
    if (localStorage.getItem("flag") !== "true") {
      localStorage.removeItem("dataKey");
      localStorage.removeItem("imei status");
      localStorage.removeItem("imeiCode");
      localStorage.removeItem("imeiNo");
    }
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    orderDetailCall();
  }, [orderDetailCall]);
  const renderUser = (
    <>
      <Customerdetail customerData={response} />
      {response && (
        <OrderDetail
          orderDetailData={response}
          verifyStatus={verifyStatus}
          orderNoSearch={state?.orderNoSearch}
          finalDecision={pickUpStatus}
        />
      )}
    </>
  );

  return (
    <div className="completeBody">
      <div className="contentMainDiv">
        <div className="detailContent">
          {isLoading ? <LoadingSpinner /> : renderUser}
        </div>
      </div>
    </div>
  );
}

export default DetailPage;
