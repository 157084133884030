import { postData, getData } from "../service/apiCalls.js";

export const verifyIMEISerial = async (endpoint, dataPost) => {
  const response = await postData(endpoint, dataPost);
  return response;
};

export const postDataResponse = async (endpoint, dataPost) => {
  const response = await postData(endpoint, dataPost);
  return response;
};

export const getDataResponse = async (endpoint) => {
  const response = await getData(endpoint);
  return response;
};

export const deleteDataResponse = async (endpoint) => {
  const response = await getData(endpoint);
  return response;
};
