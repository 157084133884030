export const searchOrderLength = 15;
//API endpoints
export const orderDetailApi = "api/order/";
export const checklistApi = "api/checklist/";
export const initUser = "api/init-user/";
export const imeiApi = "api/serial-no-Verify";
export const logOutApi = "api/logout";
export const imagePostApi = "api/image/product/upload";
export const imageDeleteApi = "api/image/product/delete/";
export const imageFetchApi = "api/image/product/view/";
export const deleteAllImagesApi = "api/image/product/delete-all/";
export const tokenExpiryChecklistPage =
  "Your session has expired, please re-initiate QC and upload images for the return order post login";
export const tokenExpiryForOtherPage =
  "Your session has expired, please re-initiate QC for the return order post login";
export const deleteAllImagesConfirmation =
  "Are you sure you want to delete all images?";
export const limitAlertText =
  " You have reached maximum limit of capturing 10 images";
export const maximumImages = 10;
export const minimumImages = 4;
export const imageConsent =
  "Please take the customer's consent before clicking images of the return product";
export const base64FileRegex = /:([a-zA-Z0-9\/+-]+);/;
export const verifiedText = "Verified Successfully";
export const failedText = "Failed";
