import React from "react";
import "../../stylesheets/Button.css";
import "../../stylesheets/MobileMedia.css";
import "../../stylesheets/DesktopMedia.css";
import "../../stylesheets/TabletMedia.css";
import "../../stylesheets/Landscape.css";
import "../../stylesheets/SmallScreenMedia.css";

function Image({
  classNameAttribute,
  action,
  testId,
  imageSource,
  altText,
  imageClass,
  id,
  disabled,
  label,
  indexNo,
  imageTestId
}) {
  return (
    <button
      onClick={action}
      className={classNameAttribute}
      data-testid={testId}
    >
      <img
        src={imageSource}
        alt={altText}
        className={imageClass}
        id={id}
        disabled={disabled}
        aria-label={label}
        tabIndex={indexNo}
        data-testid={imageTestId}
      />
    </button>
  );
}

export default Image;
