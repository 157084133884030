import { base64FileRegex } from "./Constants.js";
export const base64ToFile = (base64, filename) => {
  const arr = base64.split(",");
  const regex = base64FileRegex;
  const match = regex.exec(arr[0]);
  const mime = match ? match[1] : null;
  const bstr = window.atob(arr[1]);
  const u8arr = Uint8Array.from(bstr, (c) => c.charCodeAt(0));
  return new File([u8arr], filename, { type: mime });
};
export const base64String = (baseChar) => {
  const images = btoa(String.fromCharCode(...new Uint8Array(baseChar)));
  const imagesFormat = `data:image/png;base64,${images}`;
  return imagesFormat;
};
