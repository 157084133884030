import React, { useState, useEffect, useCallback, useContext } from "react";
import "../../stylesheets/checklistPage/ChecklistPage.css";
import "../../stylesheets/checklistPage/UploadImagesPage.css";
import Button from "../common/Button";
import otherImageCapture from "../../assets/images/otherImageCapture.png";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useLocation } from "react-router";
import Webcam from "react-webcam";
import closeImageIcon from "../../assets/images/closeImageModalIcon.png";
import { base64ToFile, base64String } from "../../config/Utils";
import {
  postDataResponse,
  getDataResponse,
  deleteDataResponse,
} from "../../service/serviceCalls";
import * as constants from "../../config/Constants";
import captureIcon from "../../assets/images/captureIcon.png";
import ImageGalleryView from "./ImageGalleryView";
import deleteAllImagesIcon from "../../assets/images/deleteAllImages.png";
import LoadingSpinner from "../detailPage/LoadingSpinner";
import { SessionContext } from "../../config/SessionContext.js";
import Image from "../common/Image.jsx";

function UploadImagesPage(props) {
  const webcamRef = React.useRef(null);
  const [imageId, setImageId] = useState([]);
  const returnData = JSON.parse(localStorage.getItem("dataKey"));
  const { state } = useLocation();
  const navigate = useNavigate();
  const categoryId = state?.productId;
  const returnReasonId = state?.returnPackagesId;
  const orderNoSearch = state?.orderNoSearch;
  let transactionId = state?.transactionId;
  const postAPi = constants.checklistApi + categoryId;
  const deleteAllImagesApi = constants.deleteAllImagesApi + transactionId;
  let returnId = state?.returnId;
  const extension = "jpeg";
  const [isCameraModalOpen, setIsCameraModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [imageResponse, setImageResponse] = useState([]);
  const [limitAlert, setLimitAlert] = useState(false);
  const [showImageGallery, setShowImageGallery] = useState({
    imageVisible: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  let imageListId = imageResponse.map((image) => image._id);
  const [imageGallerySource, setImageGallerySource] = useState();
  const [imageGallerySourceIndex, setImageGallerySourceIndex] = useState();
  let postImageApi = constants.imagePostApi + `?transactionId=${transactionId}`;
  const fetchImagesApi = constants.imageFetchApi + transactionId;
  const [consentAlert, setConsentAlert] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { updatePageReload } = useContext(SessionContext);
  const [isVideoReady, setIsVideoReady] = useState(false);

  const handleUserMedia = (stream) => {
    if (stream?.active) {
      setIsVideoReady(true);
    }
  };

  const openCameraModal = () => {
    if (imageList.length === 0) {
      setIsVideoReady(false);
      setConsentAlert(true);
    } else {
      setIsVideoReady(false);
      setIsCameraModalOpen(true);
    }
  };
  const FACING_MODE_ENVIRONMENT = "environment";
  const facingMode = FACING_MODE_ENVIRONMENT;
  const videoConstraints = {
    facingMode: FACING_MODE_ENVIRONMENT,
  };

  const limitAlertFunction = () => {
    setLimitAlert(true);
  };

  useEffect(() => {
    const bufferItems = imageResponse.map((imageArray) => imageArray.imgBuffer);
    const bufferData = bufferItems.map((item) => item.data);
    setImageList(
      bufferData.map((image) => {
        const convertedImage = base64String(image);
        return convertedImage;
      })
    );
    setIsLoading(false);
    localStorage.removeItem("isFlag");
  }, [imageResponse]);

  const apiErrorCalls = useCallback(
    (errorCode, errorMessage) => {
      if (errorCode === "ERR_NETWORK" || errorCode === "ERR_BAD_RESPONSE") {
        navigate("/NetworkError");
        return;
      }
      if (errorMessage === "Invalid token") {
        navigate(`/?tokenError=${true}`);
      }
    },
    [navigate]
  );
  const captureImage = async () => {
    if (imageResponse.length < constants.maximumImages) {
      setIsLoading(true);
      localStorage.setItem("isFlag", true);
      let imageCounter = localStorage.getItem("imageCount");
      const imageSrc = webcamRef.current.getScreenshot();
      const sequentialFileName = `image${imageCounter}.${extension}`;
      localStorage.setItem("imageCount", ++imageCounter);
      const imageFile = base64ToFile(imageSrc, sequentialFileName);
      const formData = new FormData();
      formData.append("image", imageFile);
      try {
        const response = await postDataResponse(postImageApi, formData);
        let imagePathId = response.data.data._id;
        imageId.push(imagePathId);
        fetchImages();
      } catch (error) {
        if (error.code) {
          apiErrorCalls(error.code, error.response.data.message);
        }
      }
    } else setLimitAlert(true);
  };
  const handleClose = () => {
    setShowConfirmationModal(false);
    setLimitAlert(false);
  };
  const handleConsentClose = () => {
    setConsentAlert(false);
    setIsCameraModalOpen(true);
  };
  const deleteAllImages = () => {
    setShowConfirmationModal(true);
  };

  const captureClick = (e) => {
    setShowImageGallery({ imageVisible: true });
    setImageGallerySource("All Images");
    setShowModal(true);
    setImageGallerySourceIndex(imageList.length - 1);
  };

  const cameraClose = (e) => {
    setIsCameraModalOpen(false);
  };

  const handleNo = () => setShowConfirmationModal(false);

  const deleteImages = () => {
    deleteDataResponse(deleteAllImagesApi)
      .then((response) => {
        if (response.status === 200) {
          setImageId([]);
          fetchImages();
          handleClose();
          localStorage.setItem("imageCount", 0);
        }
      })

      .catch((error) => {
        setIsLoading(false);
        if (error.code) {
          apiErrorCalls(error.code, error.response.data.message);
        }
      });
  };

  const onclick = async () => {
    updatePageReload(false);
    let dataPost = {
      answers: returnData,
      orderId: returnId,
      cqcTransactionId: transactionId,
      returnReasonId: returnReasonId,
      productImagesId: imageListId,
    };

    let status;
    try {
      status = await postDataResponse(postAPi, dataPost);
      if (status.status === 201) {
        localStorage.removeItem("dataKey");
        localStorage.removeItem("flag");
      }
    } catch (error) {
      if (error.code) {
        apiErrorCalls(error.code, error.response.data.message);
      }
    }
    let finalDecision;
    if (status && status.status === 201) {
      let returnStatus = status.status;
      finalDecision = status.data.finalDecision;
      navigate("/detail", {
        state: {
          returnStatus,
          orderNoSearch,
          finalDecision,
        },
      });
    }
  };
  const fetchImages = useCallback(async () => {
    try {
      const fetchImageResponse = await getDataResponse(fetchImagesApi);
      setImageResponse(fetchImageResponse.data.data);
      setImageResponse(fetchImageResponse.data.data);
      const bufferItems = fetchImageResponse.data.data.map(
        (imageArray) => imageArray.imgBuffer
      );
      const bufferData = bufferItems.map((item) => item.data);
      setImageList(
        fetchImageResponse.data.data.length > 0
          ? bufferData.map((image) => {
              const convertedImage = base64String(image);
              return convertedImage;
            })
          : []
      );
      setIsLoading(false);
    } catch (error) {
      if (error.code) {
        if (error.response && error.response.status === 404) {
          setIsLoading(false);
          setImageResponse([]);
          setImageList([]);
        }

        apiErrorCalls(error.code, error.response.data.message);
        if (
          error.response &&
          (error.response.data.message === "Invalid token" ||
            error.code === 401)
        ) {
          navigate(`/?tokenError=${true}`);
          return;
        }
      }
    }
  }, [fetchImagesApi, navigate, apiErrorCalls]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  return (
    <>
      <div className="imageMainDiv">
        <div className="imageUploadDiv">
          <div>
            <p className="productHeadline pt-4">Product Images</p>
          </div>
          <div>
            <p className="descPara" data-testid="instructionPara">
              Please upload clear images of product below. Minimum 4 images have
              to be uploaded including front, back, left and right side images
              of the products to proceed for QC submission
            </p>
          </div>

          <div
            className={
              imageList.length > 0 && imageList.length < constants.maximumImages
                ? "image-container"
                : "image-container-modified"
            }
          >
            {imageList.length > 0 && (
              <div
                className="captured-image-tile-container"
                data-testid="captured-image-tile-container"
              >
                <Image
                  classNameAttribute="infoIconModelButton"
                  testId="captured-image"
                  action={captureClick}
                  imageSource={imageList[imageList.length - 1]}
                  altText="Capture view of the product"
                  imageClass="captured-image-tile"
                />
                <button
                  className="image-count"
                  onClick={(e) => {
                    setShowImageGallery({ imageVisible: true });
                    setImageGallerySource("All Images");
                    setShowModal(true);
                    setImageGallerySourceIndex(imageList.length - 1);
                  }}
                  data-testid="image-count"
                >
                  {"+" + imageList.length}
                </button>
              </div>
            )}

            {imageList.length < constants.maximumImages && (
              <div className="captured-image-tile-container">
                <Image
                  classNameAttribute="infoIconModelButton"
                  testId="capture-icon"
                  action={openCameraModal}
                  imageSource={otherImageCapture}
                  altText="capture"
                  imageClass={
                    imageList.length > 0 ? "upload-image-tile" : "uploadTile"
                  }
                />
              </div>
            )}
          </div>
          {imageList.length > 0 && (
            <div className="deleteAllMainDiv">
              <div>
                <Image
                  classNameAttribute="infoIconModelButton"
                  testId="deleteAll"
                  action={deleteAllImages}
                  imageSource={deleteAllImagesIcon}
                  altText="deleteAll"
                />
              </div>
              <button className="deleteImageButton" onClick={deleteAllImages}>
                Delete all images
              </button>
            </div>
          )}
        </div>
        {showImageGallery.imageVisible && (
          <ImageGalleryView
            imageData={imageList}
            showModal={showModal}
            setShowModal={setShowModal}
            imageGallerySource={imageGallerySource}
            imagePathId={imageListId}
            imageGallerySourceIndex={imageGallerySourceIndex}
            setImageGallerySourceIndex={setImageGallerySourceIndex}
            transactionId={transactionId}
            fetchImages={fetchImages}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            data-testid="image-gallery-view"
          />
        )}
        {showConfirmationModal && (
          <Modal
            size="lg"
            className="deleteAllConfirmationModal"
            show={true}
            centered
            onHide={handleClose}
          >
            <Modal.Header
              closeButton
              className="border-0 small pb-0"
            ></Modal.Header>
            <Modal.Body className="pt-0">
              <div className="confirmationMainDiv">
                <div>
                  <p className="confirmationPara">
                    {constants.deleteAllImagesConfirmation}
                  </p>
                </div>
                <div className="optionsDiv">
                  <button
                    className="yesButton"
                    onClick={deleteImages}
                    data-testid="deleteYesButton"
                  >
                    YES
                  </button>
                  <button
                    className="noButton"
                    onClick={handleNo}
                    data-testid="deleteNoButton"
                  >
                    NO
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}

        {limitAlert || consentAlert ? (
          <Modal
            size="lg"
            className="limitAlertModal"
            show={true}
            centered
            onHide={limitAlert ? handleClose : handleConsentClose}
            data-testid="consent-alert"
          >
            <Modal.Header className="border-0 small pb-0">
              <button
                aria-label="Close"
                className="btn-close"
                data-testid="close-modal-button"
                type="button"
                onClick={limitAlert ? handleClose : handleConsentClose}
              />
            </Modal.Header>
            <Modal.Body className="pt-0">
              {limitAlert ? (
                <p className="confirmationPara">{constants.limitAlertText}</p>
              ) : (
                <p className="confirmationPara">{constants.imageConsent}</p>
              )}
            </Modal.Body>
          </Modal>
        ) : null}

        {isCameraModalOpen && (
          <Modal
            size="lg"
            show={isCameraModalOpen}
            onHide={() => setIsCameraModalOpen(false)}
            className="cameraModalBody"
            centered
            data-testid="camera-modal"
          >
            <Modal.Body className="imageModalBody">
              <div className="imageCloseDiv">
                <Image
                  classNameAttribute="infoIconModelButton"
                  testId="close-image-icon"
                  action={cameraClose}
                  imageSource={closeImageIcon}
                  altText="leftArrow"
                  imageClass="closeImage"
                  label="Close"
                />
              </div>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className="cameraModal"
                videoConstraints={{
                  ...videoConstraints,
                  facingMode,
                }}
                data-testid="webcam-ref"
                onUserMedia={handleUserMedia}
              />
            </Modal.Body>
            <Modal.Footer className="imageModalFooter">
              <div className="row modalFooterDiv">
                <div className="col-4"></div>
                {isVideoReady && (
                  <div className="col-4 text-center">
                    <div className="imageInnerDiv">
                      {isLoading ? (
                        <div data-testid="loading-spinner">
                          <LoadingSpinner />
                        </div>
                      ) : (
                        <Image
                          classNameAttribute="infoIconModelButton"
                          testId="capture icon"
                          action={
                            imageResponse.length < constants.maximumImages
                              ? captureImage
                              : limitAlertFunction
                          }
                          imageSource={captureIcon}
                          altText="capture icon"
                          imageClass="captureIcon"
                        />
                      )}
                    </div>
                  </div>
                )}
                {imageList.length > 0 && (
                  <div className="col-3 captured-images-container">
                    <div className="captured-imageModal-tile">
                      <Image
                        classNameAttribute="infoIconModelButton"
                        testId="captured-image"
                        imageSource={imageList[imageList.length - 1]}
                        altText="Capture view of the product"
                        imageClass="imageModalTile"
                      />
                      <button
                        className="image-count-overlayModal"
                        onClick={(e) => {
                          setShowImageGallery({ imageVisible: true });
                          setImageGallerySource("Preview Image");
                          setImageGallerySourceIndex(imageList.length - 1);
                          setShowModal(true);
                        }}
                        data-testid="image-count-overlay"
                      >
                        {"+" + imageList.length}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </div>
      <div className="uploadSubmitDiv">
        <div className="submitDiv">
          <Button
            disable={imageList.length < constants.minimumImages}
            name="SUBMIT"
            dataTestId="submitButton"
            className="verificationButton"
            action={onclick}
          ></Button>
          {imageList.length < constants.minimumImages && (
            <p className="instructionPara">
              Can’t proceed? upload photos first
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default UploadImagesPage;
