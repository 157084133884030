import React, { useState, useEffect, useContext, useCallback } from "react";
import "../../stylesheets/checklistPage/QuestioningPage.css";
import "../../stylesheets/checklistPage/ChecklistPage.css";
import Button from "../common/Button";
import { useNavigate, useLocation } from "react-router";
import Scanner from "./Scanner";
import * as constants from "../../config/Constants";
import LoadingSpinner from "../detailPage/LoadingSpinner";
import MyVerticallyCenteredModal from "./Modal";
import {
  getDataResponse as orderDetailService,
  verifyIMEISerial,
} from "../../service/serviceCalls.js";
import successImage from "../../assets/images/successChecklist.png";
import imageFailure from "../../assets/images/failure.png";
import Modal from "react-bootstrap/Modal";
import emptyImage from "../../assets/images/emptyImage.png";
import "bootstrap/dist/css/bootstrap.css";
import barCode from "../../assets/images/Barcode.png";
import infoIcon from "../../assets/images/infoIcon.png";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { SessionContext } from "../../config/SessionContext.js";
import Image from "../common/Image.jsx";

function QuestioningPage(props) {
  const [answer, setAnswer] = useState([]);
  const [flag, setFlag] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [infoShow, setInfoShow] = useState(false);
  const [textbox, setTextbox] = useState("");
  const [load, setLoad] = useState(true);
  const [imei, setImei] = useState("");
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const { state } = useLocation();
  const [option, setOption] = useState([]);
  const [imeiScanResult, setImeiScanResult] = useState("");
  const [images, setImages] = useState([]);
  const [instruction, setInstruction] = useState({});
  const [imeiVisible, setImeiVisible] = useState(false);
  const [imeiFailure, setImeiFailure] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [camera, setCamera] = useState(false);
  const handleInfoClose = () => setInfoShow(false);
  const handleInfoShow = () => setInfoShow(true);
  const [validateButton, setValidateButton] = useState(true);
  const isModelRegex = /model|brand/i;
  const [brandInfo, setBrandInfo] = useState(false);
  const [imeiInfo, setImeiInfo] = useState(false);
  const [statusMessage, setStatusMessage] = useState(
    localStorage.getItem("imei status")
  );
  const selectedOption = localStorage.getItem("dataKey");
  let statusCode = localStorage.getItem("imeiCode");
  const { updateErrorMessage } = useContext(SessionContext);
  const { updateImei } = useContext(SessionContext);
  const [paraVisiblity, setParaVisiblity] = useState(true);
  const [scanClicked, setScanClicked] = useState(false);
  let answerForImei = "";
  const categoryId = state?.productId;
  const skuCode = state?.skuCode;
  let totalQuestion = 0;
  const api = constants.checklistApi + categoryId + `?skuCode=${skuCode}`;
  const imeiApiPost = constants.imeiApi;
  const validationField = state?.validationField;
  let len = 0;
  const orderNoSearch = state?.orderNoSearch;
  const [image, setImage] = useState([]);
  const tokenExpiryValue = "yes";
  const imageList = image.map((image, key) => ({
    original: image.url,
    thumbnail: image.url,
    idNo: key,
  }));
  const handleClose = () => {
    setShow(false);
  };

  const iconDisplay = () => {
    handleInfoShow();
    setInfoShow(true);
    setBrandInfo(true);
    setImeiInfo(false);
  };

  const brandIcon = () => {
    handleInfoShow();
    setInfoShow(true);
    setBrandInfo(false);
    setImeiInfo(true);
  };

  const imeiStatusSuccess = () => {
    setValidateButton(false);
    setImeiVisible(true);
    setImeiFailure(false);
    setDisable(true);
  };

  useEffect(() => {
    if (statusCode === "404") {
      setImeiFailure(true);
      setImeiVisible(false);
      setDisable(true);
      setFlag(true);
    } else if (statusCode === "200") {
      imeiStatusSuccess();
      setFlag(true);
    }
  }, [statusCode]);

  const scannerClick = async () => {
    setImei("");
    setImeiScanResult("");
    setScanClicked(true);
    setModalShow(true);
    localStorage.removeItem("imeiNo");
    cameraState();
    setDisable(false);
    setImeiFailure(false);
    setImeiVisible(false);
    setFlag(false);
    setValidateButton(true);
    document.getElementById("imeiSearch").classList.remove("failure");
    answerForImei = "";
    document.getElementById("imeiSearch").classList.add("searchImei");
  };

  const galleryClick = (e) => {
    setShow(true);
    setImageIndex(e.target.id);
  };

  useEffect(() => {
    if (textbox === true) {
      imei.length !== 0 && flag && option.length === Object.keys(answer).length
        ? setParaVisiblity(false)
        : setParaVisiblity(true);
    } else {
      option?.length === Object.keys(answer).length
        ? setParaVisiblity(false)
        : setParaVisiblity(true);
    }
  }, [textbox, imei.length, flag, option.length, answer]);

  useEffect(() => {
    if (selectedOption != null) {
      const s = JSON.parse(selectedOption);
      let finalObject = {};
      s.forEach(({ questionId, answer }) => {
        Object.assign(finalObject, { [questionId]: answer });
      });

      setAnswer(finalObject);
    }
  }, [selectedOption]);

  const imeiSearchText = (event) => {
    setImei(event.target.value);

    setImeiVisible(false);
    setValidateButton(true);
    setDisable(false);
    setFlag(false);
    let newAnswer = { ...answer };
    newAnswer[event.target.name] = answerForImei;
    setAnswer(newAnswer);
  };

  const onDetected = (result) => {
    setImeiScanResult(result);
    if (document.getElementById("imeiSearch")) {
      document.getElementById("imeiSearch").value = result;
      setImei(result);
      setValidateButton(true);
    }
  };

  const cameraState = () => {
    setCamera((prevState) => !prevState);
    setImei("");
  };

  useEffect(() => {
    if (localStorage.getItem("backFlag") === "true") {
      navigate("/detail");
    }
  }, [navigate]);

  const tokenErrorMesage = useCallback(() => {
    if (localStorage.getItem("flag") === "true") {
      navigate(`/?tokenError=${true}`);
    } else {
      navigate(`/?tokenError=${tokenExpiryValue}`);
    }
  }, [navigate]);

  const apiErrorCalls = useCallback(
    (errorCode, errorMessage) => {
      if (errorCode === "ERR_NETWORK" || errorCode === "ERR_BAD_RESPONSE") {
        navigate("/NetworkError");
        return;
      }
      if (errorMessage === "Invalid token") {
        tokenErrorMesage();
      }
    },
    [navigate, tokenErrorMesage]
  );

  useEffect(() => {
    let questionService;
    async function fetchQuestions() {
      try {
        questionService = await orderDetailService(api);
        setImage(questionService.data.imageData.images);
      } catch (error) {
        if (error.code) {
          apiErrorCalls(error.code, error.response.data.message);
        }
      }
      if (questionService?.data) {
        setOption(questionService.data.data || []);
        if (questionService.data.instructions !== undefined) {
          setInstruction(questionService.data.instructions);
        }
        setImages(questionService.data.imageData || []);
        setLoad(false);
        questionService.data?.data?.map((text) =>
          text.questionType === "text" ? (
            <div key={text._id.toString()}>{setTextbox(true)}</div>
          ) : (
            "false"
          )
        );
      }
    }
    fetchQuestions();
  }, [api, navigate, tokenErrorMesage, apiErrorCalls]);

  const handleRadioChange = (e, value) => {
    const newAnswer = { ...answer };
    newAnswer[e.target.name] = value;
    setAnswer(newAnswer);
  };

  const postImeiData = async (event) => {
    let newAnswer = { ...answer };
    newAnswer[event.target.attributes.data_id.nodeValue] = imei;
    setAnswer(newAnswer);
    setFlag(true);
    localStorage.setItem("imeiNo", imei);
    let dataImei = { returnOrderNo: orderNoSearch, serialNumber: imei };
    let status;
    try {
      status = await verifyIMEISerial(imeiApiPost, dataImei);
      localStorage.setItem("imei status", status.data.message);
      setStatusMessage(localStorage.getItem("imei status"));
      localStorage.setItem("imeiCode", status.status);
      updateErrorMessage(status.data.message);
      updateImei(status.status);
    } catch (error) {
      if (error.code) {
        if (error.reponse) {
          updateImei(error.response.status);
        }
        apiErrorCalls(error.code, error.response.data.message);
        updateImei(error.response?.status);
      }
      updateErrorMessage(error?.response?.data?.message);
      localStorage.setItem("imeiCode", error?.response?.status);
      localStorage.setItem("imei status", error?.response?.data?.message);
      setStatusMessage(localStorage.getItem("imei status"));

      if (error?.response?.status === 404 || statusCode === "404") {
        setImeiFailure(true);
        setImeiVisible(false);
        setDisable(true);
      } else if (error?.response?.status === 401) {
        navigate("/");
      }
    }

    if (status && status.status === 200) {
      imeiStatusSuccess();
    }
  };
  const focus = () => {
    localStorage.getItem("imeiNo");
    document.getElementById("imeiSearch").classList.remove("failure");
    document.getElementById("imeiSearch").classList.add("searchImei");
    setValidateButton(true);
    setImeiFailure(false);
    setImeiVisible(false);
  };

  const postResponse = async (returnData) => {
    localStorage.setItem("dataKey", JSON.stringify(returnData));
    props.increment();
  };

  const onClick = () => {
    if (answer !== undefined) {
      const objectResponse = Object.entries(answer).map(([key, value]) => ({
        questionId: key,
        answer: value,
      }));
      postResponse(objectResponse);
    }
    localStorage.setItem("flag", true);
  };
  const showModal = (visible, imageIndex) => {
    return (
      <Modal
        size="lg"
        show={visible}
        onHide={handleClose}
        className="imageGalleryModalBody"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="imageGalleryModalBody">
          <ImageGallery
            items={imageList}
            showBullets={false}
            showIndex={false}
            showThumbnails={true}
            startIndex={parseInt(imageIndex)}
            lazyLoad={false}
            showPlayButton={false}
            showNav={true}
            showFullscreenButton={false}
            thumbnailPosition={"bottom"}
          />
        </Modal.Body>
      </Modal>
    );
  };
  const showIconModal = (visible, infoType, serial_info) => {
    return (
      <Modal
        size="lg"
        show={visible}
        onHide={handleInfoClose}
        className="infoModalBody"
        data-testid="infoModal"
        centered
      >
        <Modal.Header
          closeButton
          className="infoModalHeader"
          data-testid="infoModalClose"
        >
          <h5 className="modal-title">
            {brandInfo !== true ? validationField : "Brand Model"}
          </h5>
        </Modal.Header>
        <Modal.Body>
          {brandInfo === true ? (
            <p className="infoPara"> {instruction.modelNo}</p>
          ) : (
            <p className="infoPara"> {instruction.serialNo} </p>
          )}
        </Modal.Body>
      </Modal>
    );
  };

  return load ? (
    <>
      <LoadingSpinner />{" "}
    </>
  ) : (
    <div>
      <div className="checklistDiv">
        {Array.isArray(option) && option.length > 0 ? (
          <div className="checklistQuestions">
            {option.map((post, index) => {
              if (
                imei === "" &&
                post.questionType === "text" &&
                answer[post._id] !== undefined &&
                scanClicked === false
              )
                setImei(answer[post._id]);

              return (
                <div
                  key={post._id.toString()}
                  values={answer}
                  data-testid="questioning-page"
                >
                  <p className="questionText">{totalQuestion++}</p>
                  {post.questionType === "radio" ||
                  post.questionType === "images" ? (
                    <>
                      <p className="questionText">{len++}</p>
                      <div className="questionDiv">
                        <div>
                          <div className="infoQuestion">
                            <p
                              className="questionPara"
                              data-testid="questionPara"
                            >
                              {post.question}
                            </p>
                            {isModelRegex.test(post.question) === true &&
                              Object.keys(instruction).length !== 0 && (
                                <Image
                                  classNameAttribute="infoIconModelButton"
                                  action={iconDisplay}
                                  testId="info-display-button"
                                  imageSource={infoIcon}
                                  altText="information"
                                  imageClass="infoIconModel"
                                />
                              )}
                          </div>
                        </div>

                        <div className="btn-group radioOption">
                          <input
                            type="radio"
                            className="btn-check"
                            data-testid={post._id + "yes"}
                            name={post._id}
                            id={post._id + "Yes"}
                            autoComplete="off"
                            checked={answer[post._id] === "Yes"}
                            onChange={(e) => handleRadioChange(e, "Yes")}
                          />

                          <label
                            className="optionButton"
                            id="yesButton"
                            htmlFor={post._id + "Yes"}
                          >
                            Yes
                          </label>
                          <input
                            type="radio"
                            className="btn-check"
                            data-testid={post._id + "no"}
                            name={post._id}
                            id={post._id + "No"}
                            autoComplete="off"
                            checked={answer[post._id] === "No"}
                            onChange={(e) => handleRadioChange(e, "No")}
                          />

                          <label
                            className="optionButton"
                            id="noButton"
                            htmlFor={post._id + "No"}
                            data-testid="noLabel"
                          >
                            No
                          </label>
                        </div>
                      </div>

                      <div className="imageDiv">
                        {images &&
                          post.questionType === "images" &&
                          images.images.length === 0 && (
                            <img
                              src={emptyImage}
                              alt="No Images found"
                              className="noImage"
                            />
                          )}
                        {post.questionType === "images" &&
                          Array.isArray(images.images) &&
                          images.images.slice(0, 3).map((image, index) => (
                            <div
                              key={image.code}
                              className="imageDivBackground"
                            >
                              {image === "" ? (
                                <div className="emptyImage" key={image.code}>
                                  <br />
                                </div>
                              ) : (
                                <div key={image.code}>
                                  <Image
                                    classNameAttribute="infoIconModelButton"
                                    action={galleryClick}
                                    imageSource={image.url}
                                    altText="product"
                                    imageClass="productImage"
                                    id={index}
                                    testId={"productClick" + [index]}
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                      </div>

                      {post.questionType === "radio" &&
                        totalQuestion < option.length && (
                          <div className="lineDivision"></div>
                        )}
                    </>
                  ) : (
                    false
                  )}

                  {post.questionType === "text" ? (
                    <div>
                      <div className="infoQuestion">
                        <p className="questionPara" data-testid="questionPara">
                          {post.question}
                        </p>
                        <Image
                          classNameAttribute="infoIconModelButton"
                          action={brandIcon}
                          testId="icon-display-button"
                          imageSource={infoIcon}
                          altText="information"
                          imageClass="infoIconModel"
                        />
                      </div>
                      <div className="imeiRow">
                        <div className="imeii">
                          <div>
                            <input
                              className={imeiFailure ? "failure" : "success"}
                              type="search"
                              name={post._id}
                              id="imeiSearch"
                              autoComplete="off"
                              data-testid="serialimeivalid"
                              value={imei}
                              onFocus={focus}
                              onInput={(event) => {
                                imeiSearchText(event);
                                answerForImei = event.target.value;
                              }}
                            />
                          </div>
                          <div>
                            <MyVerticallyCenteredModal
                              show={modalShow}
                              data-testid="scanModal"
                              onHide={() => {
                                setModalShow((prevState) => !prevState);
                                setCamera((prevState) => !prevState);
                              }}
                              imeinumber={imeiScanResult}
                            >
                              <div className="container">
                                {camera && (
                                  <Scanner
                                    onDetected={onDetected}
                                    setCam={setCamera}
                                  />
                                )}
                              </div>
                            </MyVerticallyCenteredModal>
                            <Image
                              classNameAttribute="infoIconModelButton"
                              action={scannerClick}
                              testId="scanIcon"
                              imageSource={barCode}
                              altText="croma"
                              imageClass="checklistImageScan"
                              id="barCodeIcon"
                            />
                          </div>
                        </div>
                        <div>
                          {validateButton && (
                            <button
                              className="validateButton"
                              id="validate"
                              data_id={post._id}
                              data-testid="imeiValidateButton"
                              onClick={async (e) =>
                                imei ? await postImeiData(e) : false
                              }
                              disabled={imei ? disable : true}
                            >
                              VALIDATE
                            </button>
                          )}
                          {imeiVisible ? (
                            <div className="imeiVerificationSuccess">
                              <div className="successDiv">
                                <img
                                  src={successImage}
                                  alt="successful"
                                  className="successImage"
                                  data-testid="successIcon"
                                />
                              </div>
                              <div>
                                <p className="verifyText">{statusMessage}</p>
                              </div>
                            </div>
                          ) : (
                            false
                          )}
                        </div>
                      </div>

                      {imeiFailure ? (
                        <div className="imeiVerification">
                          <img
                            src={imageFailure}
                            alt="successful"
                            className="failureImage"
                          />
                          <p className="failureText">{statusMessage}</p>
                        </div>
                      ) : (
                        <div className="lineDivisionImage"></div>
                      )}
                    </div>
                  ) : (
                    false
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <LoadingSpinner />
        )}
        {showIconModal(infoShow, brandInfo, imeiInfo)}
        {showModal(show, imageIndex)}
      </div>
      <div className="imeiDiv">
        <div className="submitDiv">
          <Button
            disable={
              textbox
                ? !(
                    statusCode &&
                    imei.length !== 0 &&
                    flag &&
                    option.length === Object.keys(answer).length
                  )
                : !(
                    answer !== undefined &&
                    option?.length === Object.keys(answer).length
                  )
            }
            name="NEXT"
            className="verificationButton"
            data-testid="verificationButton"
            action={onClick}
          />
          {paraVisiblity && (
            <p className="instructionPara" data-testid="instructionPara">
              Can’t proceed? upload photos first
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuestioningPage;
