const initialState = {
  frontImage: "",
  backImage: "",
  additionalImages: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_FRONT_IMAGE":
      return {
        ...state,
        frontImage: action.payload,
      };
    case "ADD_BACK_IMAGE":
      return {
        ...state,
        backImage: action.payload,
      };
    case "ADD_ADDITIONAL_IMAGE":
      return {
        ...state,
        additionalImages: action.payload,
      };
    case "CLEAR_IMAGES":
      return {
        frontImage: "",
        backImage: "",
        additionalImages: [],
      };
    default:
      return state;
  }
};

export default reducer;
